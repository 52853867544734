import Layout from "@/components/Layout";
import SEO, { SEOType } from "@/components/SEO";
import { BreadCrumb } from "@/components/Shared/BreadCrumb";
import { EmbeddedAssetBlockImageUi } from "@/components/Shared/RichtextUi/EmbeddedAsset";
import { EmbeddedBlockUi } from "@/components/Shared/RichtextUi/EmbeddedBlockUi";
import {
  HeadingFive,
  HeadingFour,
  HeadingOne,
  HeadingSix,
  HeadingThree,
  HeadingTwo,
} from "@/components/Shared/RichtextUi/Headings";
import { ParagraphWide } from "@/components/Shared/RichtextUi/ParagraphWide";
import { documentToReactComponents, Options } from "@contentful/rich-text-react-renderer";
import { BLOCKS } from "@contentful/rich-text-types";
import { graphql, PageProps } from "gatsby";
import React, { Component } from "react";

export const query = graphql`
  query GET_BLOG_BY_SLUG($slug: String!) {
    contentfulBlogModelRef(slug: { eq: $slug }) {
      title
      detailedPage {
        breadcrumbTitle
        seo {
          documentTitle
          metaTitle
          metaUrl
          metaAuthor
          metaKeywords
          description {
            description
          }
        }
        content {
          raw
          references {
            ... on ContentfulAsset {
              contentful_id
              file {
                url
              }
            }
          }
        }
      }
    }
  }
`;

interface BlogDetailProps extends PageProps {
  data: {
    contentfulBlogModelRef: {
      title: string;
      detailedPage: {
        breadcrumbTitle?: string;
        seo: SEOType;
        content: {
          raw: any;
          references: any[];
        };
      };
    };
  };
}

export default class BlogDetail extends Component<BlogDetailProps> {
  private provideOptions(): Options {
    const options: Options = {
      renderNode: {
        [BLOCKS.DOCUMENT]: (node, children) => (
          <div className="container blog-container grid-demo grid-demo-underlined">{children}</div>
        ),
        [BLOCKS.PARAGRAPH]: (_, children) => <ParagraphWide paragraph={children} />,
        [BLOCKS.HEADING_1]: (_, children) => <HeadingOne heading={children} />,
        [BLOCKS.HEADING_2]: (_, children) => <HeadingTwo heading={children} />,
        [BLOCKS.HEADING_3]: (_, children) => <HeadingThree heading={children} />,
        [BLOCKS.HEADING_4]: (_, children) => <HeadingFour heading={children} />,
        [BLOCKS.HEADING_5]: (_, children) => <HeadingFive heading={children} />,
        [BLOCKS.HEADING_6]: (_, children) => <HeadingSix heading={children} />,
        "embedded-entry-block": (node) => (
          <EmbeddedBlockUi
            node={node}
            references={this.props.data.contentfulBlogModelRef.detailedPage.content.references}
          />
        ),
        "embedded-asset-block": (node) => (
          <EmbeddedAssetBlockImageUi
            node={node}
            references={this.props.data.contentfulBlogModelRef.detailedPage.content.references}
          />
        ),
      },
    };
    return options;
  }

  render(): JSX.Element {
    const {
      description: { description },
      documentTitle,
      metaKeywords,
      metaTitle,
      metaUrl,
      metaAuthor,
    } = this.props.data.contentfulBlogModelRef.detailedPage.seo;

    return (
      <Layout>
        <SEO
          contentfulSeo={{
            metaAuthor,
            metaKeywords,
            metaTitle,
            metaUrl,
            documentTitle,
            metaDescription: description,
          }}
        />
        <BreadCrumb
          activePath={{ routeName: this.props.data.contentfulBlogModelRef.title }}
          routingPaths={[
            { path: "/", routeName: "Home" },
            { path: "/blog/", routeName: "Blogs" },
          ]}
          title={
            this.props.data.contentfulBlogModelRef.detailedPage.breadcrumbTitle ||
            this.props.data.contentfulBlogModelRef.title
          }
        />
        <section className="section section-md pt-2 pb-0 pl-0 pr-0">
          <div>
            {documentToReactComponents(
              JSON.parse(this.props.data.contentfulBlogModelRef.detailedPage.content.raw),
              this.provideOptions(),
            )}
          </div>
        </section>
      </Layout>
    );
  }
}
